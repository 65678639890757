<template>
	<div class="overlay">

		<div class="overlay-box">
			<div class="overlay-topbar" style="">
				<p class="topbartext">Download Prior Surveys</p>
				<span style="cursor: pointer;" @click="$emit('close')"><b-icon class="overlay-close-icon" icon="x"></b-icon></span>
			</div>

			<div class="overlay-body">
				<p>The following responses can be retrieved</p>

				<div class="download-link-hold">
					<p v-for="(item, ind) in items" :key="ind" class="download-text" @click="doDownload(item.respid, item.survid)">{{item.respid}} ({{item.survid}}) {{item.saveTime}}</p>
				</div>

			</div>

		</div>

	</div>

</template>

<script>
export default {
	name: "DownloadPreviousResponses",
	components: {

	},
	props: {

	},
	data: function () {
		return {
			items: [],
		}
	},
	computed: {

	},
	methods: {
		getItemsFromStorage() {
			let items = []
			for (let i = 0; i < localStorage.length; i++){
				let key = localStorage.key(i)
				if(key.includes('wlsurvid_')) {
					items.push(JSON.parse(localStorage.getItem(key)))
				}
			}
			//sort items by saveTimeUnix
			items.sort((a, b) => {
				return b.saveTimeUnix - a.saveTimeUnix
			})
			this.items = items
		},
		doDownload(respid, survid) {
			this.$emit('download', {respid: respid, survid: survid})
		}
	},
	watch: {},
	mounted() {
		this.getItemsFromStorage()
	}
}
</script>

<style scoped>
.download-link-hold {
	margin-top: 20px;
}
.download-text {
	cursor: pointer;
	color: #000;
	font-size: 12px;
	display: block;
}
.download-text:hover {
	text-decoration: underline;
}
.overlay-box { /* override style.css globals */
	width: 800px;
}
</style>